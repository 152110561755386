import React from "react";
import { graphql, useStaticQuery } from "gatsby";

export const Seo = ({ title, description, pathname, children, keywords }) => {
  // Query
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          twitterUsername
          image
          siteUrl
          keywords
        }
      }
    }
  `);
  const {
    title: defaultTitle,
    description: defaultDescription,
    twitterUsername,
    image,
    siteUrl,
    keywords: defaultKeywords,
  } = data.site.siteMetadata;

  const seo = {
    title: `${defaultTitle + " - " + title}`,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
    keywords: keywords || defaultKeywords,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="keywords" content={seo.keywords} />
      {/* <script type="application/ld+json">
        {`
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://www.spookytech.com",
      "name": "Spooky technologies",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+5-601-785-8543",
        "contactType": "Customer Support"
      }
    }
  `}
      </script> */}

      {children}
    </>
  );
};
