// This is the template for all the signs
import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import AccordionContainer from "../../components/accordion/accordionContainer";
import { Seo } from "../../components/seo";
// ------------> SIGNALS (plural) page <------------ //

const Page = (props) => {
  const signals = props.data.allStrapiSignal.nodes;

  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
    >
      <div className="text-white">
        <div className=" text-md md:text-lg text-white mb-[1.3em] w-full md:w-3/4">
          Selected themes and ‘weak signals’ prompt participants to think about
          their own climate futures.
          <br />
          Here are some selected signals explored with the help of our subject
          matter experts.
        </div>
        <AccordionContainer data={signals} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query SignalPage {
    allStrapiSignal {
      nodes {
        signalHighlight
        externalLink {
          linkAddress
          linkTitle
        }

        signalTitle
        slug
        signalContent {
          data {
            signalContent
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Page;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Signals Research"}
    description={
      "Interviews and secondary research for specualtive design workshops"
    }
    keywords={"specualtive design, design research"}
  />
);
