import * as React from "react";
import { ArrowDownAccordion, ArrowRight } from "../../images/icons";
import { undelineSM } from "../../utils/cssGroups";
import ReactMarkdown from "react-markdown";
import { getWindowSize } from "../../utils/getWindowSize";

const AccordionItem = ({ dataItem, active, onToggle }) => {
  const { signalTitle, externalLink, signalContent } = dataItem;

  const contentEl = React.useRef();

  const [height, setHeight] = React.useState(0);

  const [windowSize, setWindowSize] = React.useState(
    getWindowSize().innerWidth
  );

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize().innerWidth, setHeight("min-content"));
    }
    typeof window !== "undefined" &&
      window.addEventListener("resize", handleWindowResize);
    return () => {
      typeof window !== "undefined" &&
        window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  // height;
  React.useEffect(() => {
    active && setHeight(contentEl.current.scrollHeight);
  }, [active]);

  return (
    <li
      className={` ${
        active ? "active" : ""
      }  border-2 border-b-0 last:border-b-2 border-white`}
    >
      <button
        className="flex w-full justify-between items-start p-4 group "
        onClick={onToggle}
      >
        <div
          className={`basis-4/5 capitalize text-white text-left text-base sm:text-md group-hover:text-amber-300 transition-color duration-300 ${
            active ? "text-amber-300" : ""
          }`}
        >
          {signalTitle}
        </div>

        <ArrowDownAccordion
          classes={`h-3 lg:h-4 mt-[0.25em] text-white group-hover:text-amber-300 transition-color transition-transform duration-300 mr-1 ${
            active ? "rotate-180 text-amber-300" : "rotate-0"
          } `}
        />
      </button>
      <div
        ref={contentEl}
        className={` overflow-hidden transition-all ease-out duration-300
         ${active ? " bg-amber-300" : " mt-0 bg-transparent"}`}
        style={active ? { height: height } : { height: "0px" }}
      >
        <div className="text-black px-4 py-0 text-base md:text-md mt-[1.3rem] ">
          <ReactMarkdown
            components={{
              img: ({ node, ...props }) => (
                <img
                  className="w-full md:w-1/3 mb-[0.65em]"
                  alt={"image of " + signalTitle}
                  {...props}
                />
              ),
              p: ({ node, ...props }) => (
                <p className="mb-[1.3em] last:mb-[0]" {...props} />
              ),
            }}
          >
            {signalContent.data.signalContent}
          </ReactMarkdown>
        </div>
        <div className="px-4 mt-[1.3rem] text-black capitalize">
          References/Trends:
        </div>
        {externalLink.map((link, index) => {
          return (
            <a
              key={index}
              href={`${link.linkAddress}`}
              target="_blank"
              rel="noopener noreferrer"
              className={`${undelineSM}  text-black flex items-center px-4 my-[1.3rem]`}
            >
              <ArrowRight
                classes={
                  "h-3 w-[20px] pt-[0.2em] text-black  duration-300 mr-5 "
                }
              />
              <p className="m-0">{link.linkTitle}</p>
            </a>
          );
        })}
      </div>
    </li>
  );
};

export default AccordionItem;
