import * as React from "react";
import AccordionItem from "../accordion/accordionItem";

const AccordionContainer = ({ data }) => {
  const [clicked, setClicked] = React.useState("0");

  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked("0");
    }
    setClicked(index);
  };

  return (
    <ul className="list-none mb-0">
      {data.map((dataItem, index) => (
        <AccordionItem
          onToggle={() => handleToggle(index)}
          active={clicked === index}
          key={index}
          dataItem={dataItem}
        />
      ))}
    </ul>
  );
};

export default AccordionContainer;
